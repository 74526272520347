import { call, put, all, fork } from 'redux-saga/effects';
import {
  SET_ORDERS,
  SET_ORDER_BY_NO,
  SET_PUT_ORDER,
  SET_LOADING_ORDER,
  SET_FAILED_ORDERS,
  MERGE_ORDERS,
} from './order.action';
import {
  getOrdersApi,
  getOrderByNoApi,
  putOrderApi,
  searchOrdersApi,
  cancelShipmentApi,
  getLogisticFailedOrdersApi,
  updateFailedOrderApi,
  getPresaleOrdersApi,
  getOrderPaymentStatusApi,
  getPrimeMembershipByOrderNoApi,
  getPgTransactionsApi,
} from './order.api';
import { notification } from 'antd';
import { SET_MASTER_DATA } from '../master-data/master.action';
import { getAdc, getCity } from '../master-data/master.api';
import { ORDER_TYPES } from '../../../util/ConstantsService';

export function* getOrdersSaga({ data = {} }) {
  try {
    yield put({ type: SET_LOADING_ORDER, data: true });
    let api = getOrdersApi;
    if (data.isPresale) api = getPresaleOrdersApi;
    if (data.page) {
      const { data: orders } = yield call(api, data);
      yield put({ type: MERGE_ORDERS, data: { ...data, orders } });
    } else {
      const promises = [
        call(api, data),
        call(getAdc),
        call(getCity),
        call(api, { orderType: ORDER_TYPES.PENDING_ORDERS }),
        call(api, { orderType: ORDER_TYPES.TODAYS_ORDERS }),
        call(api, { orderType: ORDER_TYPES.TOMORROWS_ORDERS }),
      ];
      const [
        { data: orders },
        { data: adc },
        { data: city },
        { data: pendingOrders },
        { data: todaysOrders },
        { data: tomorrowsOrders },
      ] = yield all(promises);
      yield put({ type: SET_MASTER_DATA, data: { adc, city } });
      yield put({
        type: SET_ORDERS,
        data: { orders, pendingOrders, todaysOrders, tomorrowsOrders },
      });
    }
  } catch (e) {
    console.log('Err@ getOrdersSaga: ', e);
    notification.error({
      message: 'Error fetching orders. Please try later.',
    });
  }
}

export function* getOrderByNoSaga({ data }) {
  try {
    yield put({ type: SET_LOADING_ORDER, data: true });
    const orderResp = yield call(getOrderByNoApi, data);
    let pgTnxResp = null;
    try {
      pgTnxResp = yield call(getPgTransactionsApi, data);
    } catch (e) {
      console.log('Failed to get pg transactions.', e);
    }
    yield put({
      type: SET_ORDER_BY_NO,
      data: {
        orderDetails: {
          ...orderResp.data,
          pgTransactions: pgTnxResp?.data || [],
        },
      },
    });
    yield fork(getPrimeMembershipByOrderNoSaga, { data });
  } catch (e) {
    console.log('Err@ getOrderByIdSaga: ', e);
    notification.error({
      message: 'Error getting order details. Please try later.',
    });
    yield put({ type: SET_LOADING_ORDER, data: false });
  }
}

export function* getPrimeMembershipByOrderNoSaga({ data }) {
  try {
    const { data: membershipDetails } = yield call(
      getPrimeMembershipByOrderNoApi,
      data
    );
    yield put({
      type: SET_ORDER_BY_NO,
      data: { membershipDetails },
    });
  } catch (e) {
    console.log('Err@ getPrimeMembershipByOrderNoSaga: ', e);
    if (!e.data?.startsWith('No records')) {
      notification.error({
        message: 'Error getting prime membership details.',
      });
      yield put({
        type: SET_ORDER_BY_NO,
        data: { membershipDetails: null },
      });
    }
  }
}

export function* putOrderSaga({ data }) {
  try {
    let message = 'Order updated successfully.';
    console.log('Upsert Data: ', JSON.stringify(data));
    const { data: orderDetail } = yield call(putOrderApi, data);
    notification.success({ message });
    yield put({ type: SET_PUT_ORDER, data: orderDetail });
  } catch (e) {
    console.log('Err@ putOrderSaga: ', e);
    notification.error({
      message: 'Error updating order details. Please try later.',
    });
  }
}

export function* searchOrdersSaga({ data }) {
  try {
    yield put({ type: SET_LOADING_ORDER, data: true });
    const orderResp = yield call(searchOrdersApi, data);
    yield put({
      type: SET_ORDERS,
      data: { orders: orderResp.data },
    });
  } catch (e) {
    console.log('Err@ searchOrdersSaga: ', e);
    notification.error({
      message: 'Error getting order details. Please try later.',
    });
  }
}

export function cancelShipmentSaga({ data }) {
  return cancelShipmentApi(data);
}

export function* getLogisticFailedOrdersSaga() {
  try {
    yield put({ type: SET_LOADING_ORDER, data: true });
    const { data } = yield call(getLogisticFailedOrdersApi);
    yield put({ type: SET_FAILED_ORDERS, data });
  } catch (e) {
    console.log('Err@ getLogisticFailedOrdersSaga: ', e);
    yield put({ type: SET_LOADING_ORDER, data: false });
    notification.error({ message: 'Error getting failed orders' });
  }
}

export function* updateFailedOrdersSaga({ data }) {
  try {
    yield put({ type: SET_LOADING_ORDER, data: true });
    const { data: message } = yield call(updateFailedOrderApi, data);
    data.cb && data.cb();
    notification.success({ message });
    yield call(getLogisticFailedOrdersSaga);
  } catch (e) {
    console.log('Err@ updateFailedOrdersSaga: ', e);
    yield put({ type: SET_LOADING_ORDER, data: false });
    const message =
      e?.data ||
      e?.response?.data?.error?.errorMessage ||
      'Error updating failed orders';
    notification.error({ message });
    data.cb && data.cb();
  }
}

export function getOrderPaymentStatusSaga({ data }) {
  return getOrderPaymentStatusApi(data);
}
